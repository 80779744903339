import { CheckIcon, ChevronLeftIcon, PlusIcon } from "@radix-ui/react-icons";
import { Button } from "../components/Button";
import { useMutateStorage, useStorage } from "../../state/liveblocks.config";
import { Spread } from "../../types/room/types";

import pastPresentFuture from "../../assets/spreads/past-present-future.json";
import mindBodySpirit from "../../assets/spreads/mind-body-spirit.json";

import personaObstacleSolution from "../../assets/spreads/persona-obstacle-solution.json";
import noneSpread from "../../assets/spreads/none.json";
import celticCross from "../../assets/spreads/celtic-cross.json";
import glowUp from "../../assets/spreads/glow-up.json";
import bridgeBetween from "../../assets/spreads/bridge-between-you.json";
import exploringWhatIf from "../../assets/spreads/exploring-what-if.json";
import giveAndReceive from "../../assets/spreads/give-and-receive.json";
import howToHandleIt from "../../assets/spreads/how-to-handle-it.json";
import stayOrLeave from "../../assets/spreads/stay-or-leave.json";
import seedSproudHarvest from "../../assets/spreads/seed-sprout-harvest.json";
import knownUnknown from "../../assets/spreads/known-unknown.json";
import cardOfTheYear from "../../assets/spreads/card-of-the-year.json";
import bigFeelings from "../../assets/spreads/big-feelings.json";
import bornToForcedTo from "../../assets/spreads/born-to-forced-to.json";
import careerCrossroads from "../../assets/spreads/career-crossroads.json";
import channelAvoid from "../../assets/spreads/channel-avoid.json";
import collaborationEnergy from "../../assets/spreads/collaboration-energy.json";
import collectiveEnergy from "../../assets/spreads/collective-energy.json";
import creativeDirection from "../../assets/spreads/creative-direction.json";
import dateEnergy from "../../assets/spreads/date-energy.json";
import energyRefresh from "../../assets/spreads/energy-refresh.json";
import findingHome from "../../assets/spreads/finding-home.json";
import findingYourMagic from "../../assets/spreads/finding-your-magic.json";
import findingYourPeople from "../../assets/spreads/finding-your-people.json";
import gettingUnstuck from "../../assets/spreads/getting-unstuck.json";
import goalsAndAspirations from "../../assets/spreads/goals-and-aspirations.json";
import lastNightThisMorning from "../../assets/spreads/last-night-this-morning.json";
import lookingForward from "../../assets/spreads/looking-forward.json";
import meetingEnergy from "../../assets/spreads/meeting-energy.json";
import messageFromMyDeck from "../../assets/spreads/message-from-my-deck.json";
import newConnection from "../../assets/spreads/new-connection.json";
import pathTogether from "../../assets/spreads/path-together.json";
import plotTwistGrowth from "../../assets/spreads/plot-twist-growth.json";
import productMarketFit from "../../assets/spreads/product-market-fit.json";
import progressCheck from "../../assets/spreads/progress-check.json";
import settingIntentions from "../../assets/spreads/setting-intentions.json";
import strengthWeaknessMagic from "../../assets/spreads/strength-weakness-magic.json";
import swot from "../../assets/spreads/swot.json";
import todaysForecast from "../../assets/spreads/todays-forecast.json";
import unclearFeelings from "../../assets/spreads/unclear-feelings.json";
import whatThatTextMeant from "../../assets/spreads/what-that-text-meant.json";
import yesterdayTodayTomorrow from "../../assets/spreads/yesterday-today-tomorrow.json";
import yesOrNo from "../../assets/spreads/yes-or-no.json";
import betterCommunication from "../../assets/spreads/better-communication.json";
import actOrWait from "../../assets/spreads/act-or-wait.json";
import relationshipCheckIn from "../../assets/spreads/relationship-check-in.json";
import attractingLove from "../../assets/spreads/attracting-love.json";
import conflictAndResolution from "../../assets/spreads/conflict-and-resolution.json";

import { StyledLeftListItem, StyledLeftMenu } from "./StyledMenus";

import { useState } from "react";
import { trackEvent } from "~/api/analyticsApi";

export const allSpreads: Spread[] = [

  noneSpread as unknown as Spread,

  // Everyday
  personaObstacleSolution as unknown as Spread,
  howToHandleIt as unknown as Spread,
  giveAndReceive as unknown as Spread,
  todaysForecast as unknown as Spread,
  lookingForward as unknown as Spread,
  energyRefresh as unknown as Spread,
  progressCheck as unknown as Spread,
  yesterdayTodayTomorrow as unknown as Spread,
  lastNightThisMorning as unknown as Spread,

  // Self-Care
  settingIntentions as unknown as Spread,
  mindBodySpirit as unknown as Spread,
  bornToForcedTo as unknown as Spread,
  glowUp as unknown as Spread,
  bigFeelings as unknown as Spread,
  exploringWhatIf as unknown as Spread,
  plotTwistGrowth as unknown as Spread,
  strengthWeaknessMagic as unknown as Spread,

  // Relationships
  relationshipCheckIn as unknown as Spread,
  actOrWait as unknown as Spread,
  newConnection as unknown as Spread,
  dateEnergy as unknown as Spread,
  attractingLove as unknown as Spread,
  betterCommunication as unknown as Spread,
  conflictAndResolution as unknown as Spread,
  unclearFeelings as unknown as Spread,
  pathTogether as unknown as Spread,
  findingYourPeople as unknown as Spread,
  whatThatTextMeant as unknown as Spread,

  // Planning
  celticCross as unknown as Spread,
  stayOrLeave as unknown as Spread,
  yesOrNo as unknown as Spread,
  goalsAndAspirations as unknown as Spread,
  pastPresentFuture as unknown as Spread,
  knownUnknown as unknown as Spread,
  bridgeBetween as unknown as Spread,
  cardOfTheYear as unknown as Spread,
  careerCrossroads as unknown as Spread,
  findingHome as unknown as Spread,

  // Creativity
  gettingUnstuck as unknown as Spread,
  seedSproudHarvest as unknown as Spread,
  collaborationEnergy as unknown as Spread,
  creativeDirection as unknown as Spread,

  // Business
  findingYourMagic as unknown as Spread,
  meetingEnergy as unknown as Spread,
  productMarketFit as unknown as Spread,
  swot as unknown as Spread,
];


const BLANK_CATEGORY_STRING = "undefined";

// iterate through all spreads, find the category prop, to create a list of categories mapped to respective spreads
const spreadCategories = allSpreads.reduce(
  (acc: { [key: string]: Spread[] }, spread) => {
    const category = spread.category;
    if (category && category !== BLANK_CATEGORY_STRING) {
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(spread);
    }
    return acc;
  },
  {}
);

const blankSpread = allSpreads.find(
  (spread) => !spread.category || spread.category === BLANK_CATEGORY_STRING
);

export function SpreadMenu({
  isOpen,
  setIsOpen,
  onSpreadChanged,
}: {
  isOpen: boolean;
  setIsOpen: Function;
  onSpreadChanged: Function;
}) {
  const updateStorage = useMutateStorage();
  const currentSpread = useStorage((root) => root.spread);
  const theme = useStorage((root) => root.theme);
  const [showSpreadImporter, setShowSpreadImporter] = useState(false);
  const [activeCategory, setActiveCategory] = useState(BLANK_CATEGORY_STRING);

  const deckSelectMenu = (
    <StyledLeftMenu
      id={"shuffle-deck"}
      Theme={theme}
      scrollable={true}
      style={{
        maxHeight: "200px",
      }}
    >
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{}}
        transparent
        onClick={() => {
          setIsOpen(false);
        }}
      >
        &larr;
      </Button>
      <div
        className="menu-title"
        style={{
          marginBottom: "4px",
          marginTop: "5px",
          paddingBottom: "10px",
          fontSize: "12px",
          display: "flex",
          justifyContent: "left",
          paddingLeft: "14px",
          alignItems: "center",
          height: "30px",
          borderBottom: "1px solid rgba(127, 127, 127, 0.3)",
        }}
      >
        {activeCategory === BLANK_CATEGORY_STRING ? (
          "Pick a spread topic"
        ) : (
          <div
            className="category-title"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ChevronLeftIcon
              style={{
                cursor: "pointer",
                marginRight: "-1px",
                padding: "4px",
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                marginLeft: "-10px",
              }}
              onClick={() => {
                setActiveCategory(BLANK_CATEGORY_STRING);
              }}
            />

            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setActiveCategory(BLANK_CATEGORY_STRING);
              }}
            >
              {activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1)}{" "}
              spreads
            </span>
          </div>
        )}
      </div>
      {activeCategory === BLANK_CATEGORY_STRING && (
        <>
          <StyledLeftListItem key={0} Theme={theme}>
            <Button
              Theme={theme}
              style={{ fontSize: "11px"
               }}
              transparent
              transparentHover
              menuOption
              onClick={() => {
                trackEvent("/room/choose_spread", {
                  spreadname: blankSpread!.name,
                });
                updateStorage((storage) => {
                  storage.set("spread", blankSpread!);
                });
                setIsOpen(false);
                onSpreadChanged();
              }}
            >
              {blankSpread!.name}
              <CheckIcon
                style={{
                  opacity: blankSpread!.name === currentSpread.name ? 1 : 0,
                  flexShrink: 0,
                  marginRight: "-12px",
                }}
              />
            </Button>
          </StyledLeftListItem>
          {Object.keys(spreadCategories).map((category, i) => (
            <StyledLeftListItem Theme={theme}>
              <Button
                Theme={theme}
                style={{
                  fontSize: "11px",
                  fontWeight: currentSpread.category === category ? "500" : ""
                }}
                transparent
                transparentHover
                menuOption
                key={i}
                onClick={(e) => {
                  setActiveCategory(category);
                  (
                    e.currentTarget as HTMLElement
                  ).parentElement?.parentElement?.scrollTo(0, 0);
                }}
              >

                {category.charAt(0).toUpperCase() + category.slice(1)}{currentSpread.category === category ? " \u00A0✓" : ""}
                <PlusIcon
                  style={{
                    flexShrink: 0,
                    marginRight: "-12px",
                    height: "13px",
                    width: "13px",
                    opacity: ".85",
                    marginLeft: "auto"
                  }}
                />
              </Button>
            </StyledLeftListItem>
          ))}
        </>
      )}

      {activeCategory !== BLANK_CATEGORY_STRING &&
        spreadCategories[activeCategory].map((spread, i) => (
          <StyledLeftListItem key={i} Theme={theme}>
            <Button
              Theme={theme}
              style={{ fontSize: "11px" }}
              transparent
              transparentHover
              menuOption
              onClick={() => {
                trackEvent("/room/choose_spread", {
                  spreadname: spread.name,
                });
                updateStorage((storage) => {
                  storage.set("spread", spread);
                });

                onSpreadChanged();
              }}
            >
              {spread.name}
              <CheckIcon
                style={{
                  opacity: spread.name === currentSpread.name ? 1 : 0,
                  flexShrink: 0,
                  marginRight: "-12px",
                }}
              />
            </Button>
          </StyledLeftListItem>
        ))}
    </StyledLeftMenu>
  );

  return (
    <div>
      {isOpen ? (
        deckSelectMenu
      ) : (
        <Button
          Theme={theme}
          className="nav-button nav-bar-button"
          style={{
            backgroundColor:
              theme === "light"
                ? "hsla(0,0%, 92%, 0.8)"
                : "hsla(0, 0%, 10%, 0.7)",
            padding: "4px 6px 4px 4px",
            marginLeft: "-1px",
            boxShadow: "none",
            borderRadius: "6px",
          }}
          smallTransparent
          onClick={() => {
            setIsOpen(true);
          }}
        >
          + Select spread
        </Button>
      )}
    </div>
  );
}
